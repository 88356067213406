import React, { useState } from "react";
import {
  AiOutlineDelete,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useAllContext } from "./context/context";
import LoadingSpinner from "./loading-spinner";
import OpenModal from "./open-modal";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Books = () => {
  const [visible, setVisible] = useState(8);
  const showMoreBooks = () => {
    setVisible((pervValue) => pervValue + 4);
  };

  const {
    cart,
    allBooks,
    allWords,
    addToCart,
    handleChange,
    handleRemove,
    query,
    myRef,
  } = useAllContext();

  return (
      <div id="books" className="books section-padding section-bg" ref={myRef}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title-center text-center">
                <h2 className="display-6">Livros</h2>
                <div className="section-divider divider-triangle"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {allBooks.length === 0 ? (
                <LoadingSpinner className="mb-4 col-md-6 col-lg-3"/>
            ) : (
                allBooks
                    .filter((book) => book.title.toLowerCase().includes(query))
                    .slice(0, visible)
                    .map((book) => (
                        <div className="col-lg-3 col-md-6 mb-4" key={book.id}>
                          <div className="books__book">
                            <img
                                className="img-fluid"
                                src={book.img}
                                alt={book.title}
                            />
                            {book.offer === "0" ? (
                                ""
                            ) : (
                                <span className="books__book__discount">
                        <span className="on-sale">-{book.offer}%</span>
                      </span>
                            )}
                            <ul className="functional-icons">
                              <li>
                                <button
                                    onClick={() => addToCart(book)}
                                    className="icon"
                                >
                                  <AiOutlineShoppingCart/>
                                </button>
                              </li>
                              <li>
                                <OpenModal
                                    book={book}
                                    handleRemove={handleRemove}
                                    handleChange={handleChange}
                                    addToCart={addToCart}
                                />
                              </li>
                            </ul>
                            <div className="books__book__bottom">
                              <h3 className="books__book__bottom--title">
                                {book.title}
                              </h3>
                              <p className="books__book__bottom--subtitle">
                                {book.subtitle}
                              </p>
                              <p className="books__book__bottom--author">
                                Autor: <span>{book.author}</span>
                              </p>
                              <div className="price">
                                Preço: {book.price == book.offerPrice ? (
                                  <>
                                    <span>{parseFloat(book.price).toLocaleString('pt-br', {minimumFractionDigits: 2})}Kz</span>
                                  </>
                              ) : (
                                  <>
                                    <del>{parseFloat(book.price).toLocaleString('pt-br', {minimumFractionDigits: 2})}Kz</del>
                                    {" "}
                                    <span>{parseFloat(book.offerPrice).toLocaleString('pt-br', {minimumFractionDigits: 2})}Kz</span>
                                  </>
                              )}
                              </div>
                              <div className="books__book__bottom--button">
                                {cart.find((data) => data.id === book.id) ? (
                                    <>
                                      {cart.map((newData) =>
                                          newData.id === book.id ? (
                                              <div key={newData.id} className="calculation">
                                                <div className="calculation__button">
                                                  {newData.amount === 1 ? (
                                                      <button
                                                          onClick={() => handleRemove(book.id)}
                                                      >
                                                        <AiOutlineDelete/>
                                                      </button>
                                                  ) : (
                                                      <button
                                                          onClick={() =>
                                                              handleChange(newData, -1)
                                                          }
                                                      >
                                                        <AiOutlineMinus/>
                                                      </button>
                                                  )}
                                                  <span>{newData.amount}</span>
                                                  <button
                                                      onClick={() => handleChange(newData, 1)}
                                                  >
                                                    <AiOutlinePlus/>
                                                  </button>
                                                </div>
                                                <span>{parseFloat(newData.total).toLocaleString('pt-br', {minimumFractionDigits: 2})}Kz</span>
                                              </div>
                                          ) : (
                                              ""
                                          )
                                      )}
                                    </>
                                ) : (
                                    <button
                                        onClick={() => addToCart(book)}
                                        className="button button__primary"
                                    >
                            <span>
                              <AiOutlineShoppingCart/>
                              Adicionar ao carrinho
                            </span>
                                    </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                    ))
            )}
          </div>
          <div className="book-load-btn text-center mt-4 mb-4">
            {allBooks.length <= visible || query !== "" ? (
                ""
            ) : (
                <button onClick={showMoreBooks} className="button button__primary ">
                  <span> Carregue mais </span>
                </button>
            )}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
              <div className="section-title-center text-center">
                <h2 className="display-6">Palavra do dia</h2>
                <div className="section-divider divider-triangle"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {allWords.length === 0 ? (
                <LoadingSpinner className="mb-4 col-md-6 col-lg-3"/>
            ) : (
                <div className="container-fluid hero-modern">
                  <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      height="1em"
                      width="1em"
                  >
                    <path
                        d="M3.691 6.292C5.094 4.771 7.217 4 10 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 006.925 10H10a1 1 0 011 1v7c0 1.103-.897 2-2 2H3a1 1 0 01-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789zM20 20h-6a1 1 0 01-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789C16.094 4.771 18.217 4 21 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0017.925 10H21a1 1 0 011 1v7c0 1.103-.897 2-2 2z"/>
                  </svg>
                  <Swiper
                      modules={[Navigation, Autoplay]}
                      loop={false}
                      spaceBetween={50}
                      slidesPerView={4}
                      autoplay
                      navigation={{clickable: true}}
                      className="hero__swiper"
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        576: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        1200: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        1400: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                      }}
                  >
                    {allWords
                        .filter((word) => word.desc.toLowerCase().includes(query))
                        .slice(0, visible)
                        .map((word) => (
                            <SwiperSlide className="hero-modern__card" key={word.id}>
                              <div className="row align-items-center">
                                <div className="col-12 g-0 text-center p-3">
                                  <div className="ps-3">
                                    <blockquote>
                                      <h5>
                                        {word.desc}
                                      </h5>
                                      <p>{word.author}</p>
                                    </blockquote>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                        ))}
                  </Swiper>
                </div>
            )}
          </div>
          <div className="book-load-btn text-center mt-4">
            {allWords.length <= visible || query !== "" ? (
                ""
            ) : (
                <button onClick={showMoreBooks} className="button button__primary">
                  <span>Carregue mais</span>
                </button>
            )}
          </div>
        </div>
      </div>
  );
};

export default Books;
