import { useForm } from "react-hook-form";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useAllContext } from "./context/context";

const Confirm = ({ backToCart, setConfirm }) => {
  const { cart, setCart, price, allPages } = useAllContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const whatsappNumber = allPages[0].cellphone;
  const onSubmit = (customerData, e) => {
    window.open(
      `https://wa.me/${whatsappNumber}?text=Ol%C3%A1%2C%20gostaria%20de%20fazer%20um%20pedido.%0A%0A-----DETALHES%20DO%20PEDIDO-----%0A${cart.map(
        (data) =>
          `-%20${data.title}%20-%20${parseFloat(data.amount).toLocaleString('pt-br', {minimumFractionDigits: 2})}%20c%C3%B3pias.%20Pre%C3%A7o%3A%20${parseFloat(data.total).toLocaleString('pt-br', {minimumFractionDigits: 2})}Kz%0A`  
      )}%0AVALOR%20TOTAL%20-%20${parseFloat(price).toLocaleString('pt-br', {minimumFractionDigits: 2})}Kz%0A%0A-----INFORMA%C3%87%C3%95ES%20DO%20CLIENTE-----%0ANome%3A%20${
        customerData.name
      }%0AN%C3%BAmero%3A%20${customerData.number}%0AEmail%3A%20${
        customerData.email
      }%0AEndere%C3%A7o%3A%20${customerData.address}%0AC%C3%B3digo%20Postal%3A%20${
        customerData.postalCode
      }%0ACasa%3A%20${
        customerData.homeAddress
      }%0A%0A-------------------%0AConfirmaremos%20seu%20pedido%20ao%20receber%20a%20mensagem.`
    );
    e.target.reset();
    setCart([]);
    setConfirm(false);
  };

  return (
    <div className="confirm">
      <div className="row">
        <div className="section-title-center text-center">
          <div className="confirm__close" onClick={backToCart}>
            <HiArrowNarrowLeft />
          </div>
          <h2 className="fs-5">Checkout</h2>
          <div className="section-divider divider-triangle"></div>
        </div>
      </div>
      <form
        className="confirm__form bs-scroll"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-12 mb-4">
            <h3 className="title">Informações de contato</h3>
            <input
              {...register("number", { required: true })}
              type="number"
              placeholder="Número de telefone"
              className="mt-0"
            />
            {errors.number && <p>O número de telefone é obrigatório</p>}
            <input
              {...register("email", { required: true })}
              type="email"
              placeholder="Endereço de email"
            />
            {errors.email && <p>O e-mail é obrigatório</p>}
          </div>
          <div className="col-12">
            <h3 className="title">Endereço para envio</h3>
            <input
              {...register("name", { required: true })}
              type="text"
              placeholder="Seu nome"
            />
            {errors.name && <p>O nome é obrigatório</p>}
            <textarea
              {...register("address", { required: true })}
              type="text"
              placeholder="Insira o endereço"
              rows="3"
            />
            {errors.address && <p>Endereço é necessário</p>}
          </div>
          <div className="col-md-6 mb-3 mb-md-0">
            <input
              {...register("postalCode", { required: true })}
              type="number"
              placeholder="Código postal"
            />
            {errors.postalCode && <p>O código postal é obrigatório</p>}
          </div>
          <div className="col-md-6">
            <input
              {...register("homeAddress", { required: true })}
              type="text"
              placeholder="Apartamento, Bairro, etc."
            />
            {errors.homeAddress && <p>O endereço da morada é obrigatório</p>}
          </div>
          <div className="cart__confirm">
            <button type="submit" className="button button__primary">
              <span>Confirmar pedido</span>
            </button>
          </div>
        </div>
        <span className="text-center alert alert-success mt-3" role="alert">
          [Nota: Seu pedido será confirmado via Whatsapp. Você será redirecionado
          para o WhatsApp após clicar no botão 'Confirmar pedido'.]
        </span>
      </form>
    </div>
  );
};

export default Confirm;
